import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

type id2Translate = {
  id: number
  model: string
}

export type Translation = {
  model: string,
  hashed_id: string
}

interface IIdTranslatorService {
  $axios: NuxtAxiosInstance,
  translate: (ids2Translate: Array<id2Translate>) => Promise<Translation>
}

export default class IdTranslatorService implements IIdTranslatorService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  translate (ids2Translate: Array<id2Translate>) {
    return this.$axios.$post('/api/encode-ids', ids2Translate)
  }
}
